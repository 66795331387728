import { IAction, IEventDetails } from "../../types/types";
import {
  EVENT_DETAILS_FETCH_FAIL,
  EVENT_DETAILS_FETCH_REQUEST,
  EVENT_DETAILS_FETCH_SUCCESS,
  EVENT_DETAILS_SET_INIT_DATA,
} from "../actions/eventDetails.actions";

export interface IState {
  eventRequest: {
    isLoading: boolean;
    isError: boolean;
    event: IEventDetails | null;
  };
  initData: {
    eventId: string;
    eventName: string;
    eventImage: string;
  } | null;
}

const initialState: IState = {
  eventRequest: {
    isLoading: false,
    isError: false,
    event: null,
  },
  initData: null,
};

export const reducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case EVENT_DETAILS_SET_INIT_DATA:
      return {
        ...state,
        initData: action.payload,
      };

    case EVENT_DETAILS_FETCH_REQUEST:
      return {
        ...state,
        eventRequest: {
          ...state.eventRequest,
          isLoading: true,
          isError: false,
        },
      };

    case EVENT_DETAILS_FETCH_SUCCESS:
      return {
        ...state,
        eventRequest: {
          ...state.eventRequest,
          isLoading: false,
          event: action.payload,
        },
      };

    case EVENT_DETAILS_FETCH_FAIL:
      return {
        ...state,
        eventRequest: {
          ...state.eventRequest,
          isLoading: false,
          isError: true,
        },
      };

    default:
      return state;
  }
};
