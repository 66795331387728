import axios from "axios";
import { NODE_ENV } from "../../config/config";

export const SANDBOX_BASE_URL = "https://lineapp-sandbox.com/api";
export const DEV_BASE_URL = "http://192.168.1.189:3000/api";
export const PROD_BASE_URL = "https://lineapp-dev.com/api";

export function onGetBaseUrl(): string {
  switch (NODE_ENV) {
    case "development":
      return DEV_BASE_URL;

    case "sandbox":
      return SANDBOX_BASE_URL;

    case "production":
      return PROD_BASE_URL;
  }
}

export const BASE_URL = onGetBaseUrl();

export const lineappAxios = axios.create({
  baseURL: BASE_URL,
  validateStatus: (status: number) => status < 300,
});
