export const COLORS = {
  primary: "#db0b6f",
  // primary: '#f3347e',
  primaryRGB: "219, 11, 112",
  secondary: "#6f11cf",
  blackBackground: "#0D0D0D",
  loadingBackgroundColor: "#333",
  black: "#000",
  lightBlack: "#222",
  white: "#FFFFFF",
  grey: "#787777",
  lightGrey: "#D3D3D3",
  orange: "#7FDBFF",
  opacityPrimary: "rgba(48, 163, 191, 0.1)",
  lightRed: "rgba(255, 20, 20, 0.75)",
  green: "#32a852",
  darkGrey: "#777",
  lowGreyOpacity: "rgba(225, 225, 225, 0.2)",
  medGreyOpacity: "rgba(225, 225, 225, 0.4)",
  highGreyOpacity: "rgba(225, 225, 225, 0.7)",
  overlay: "rgba(0, 0, 0, 0.35)",
  transparnet: "rgba(0, 0, 0, 0)",
};
