export function joinClasses(arr: any) {
  let classes = "";

  for (const tempClass of arr) {
    if (tempClass) {
      classes += tempClass + " ";
    }
  }

  return classes;
}
