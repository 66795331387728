import { IAction, IEvent } from "../../types/types";
import {
  HOME_EVENTS_FETCH_FAIL,
  HOME_EVENTS_FETCH_REQUEST,
  HOME_EVENTS_FETCH_SUCCESS,
} from "../actions/home.actions";

export interface IState {
  eventsRequest: {
    isLoading: boolean;
    isError: boolean;
    events: IEvent[];
  };
}

const initialState: IState = {
  eventsRequest: {
    isLoading: false,
    isError: false,
    events: [],
  },
};

export const reducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case HOME_EVENTS_FETCH_REQUEST:
      return {
        ...state,
        eventsRequest: {
          ...state.eventsRequest,
          isLoading: true,
          isError: false,
        },
      };

    case HOME_EVENTS_FETCH_SUCCESS:
      return {
        ...state,
        eventsRequest: {
          ...state.eventsRequest,
          isLoading: false,
          events: action.payload,
        },
      };

    case HOME_EVENTS_FETCH_FAIL:
      return {
        ...state,
        eventsRequest: {
          ...state.eventsRequest,
          isLoading: false,
          isError: true,
        },
      };

    default:
      return state;
  }
};
