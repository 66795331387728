import { createInstance } from "i18next";
import { initReactI18next } from "react-i18next";
import translationEn from "./locales/en.json";
import translationHe from "./locales/he.json";
import LanguageDetector from "i18next-browser-languagedetector";

const i18n = createInstance({
  fallbackLng: "en",
  debug: true,

  interpolation: {
    escapeValue: false,
  },
  lng: "",
  // keySeparator: false,
  // ns: ['translations'],
  // defaultNS: 'translations',
  resources: {
    en: {
      translation: translationEn,
    },
    he: {
      translation: translationHe,
    },
  },
});

i18n.use(initReactI18next).use(LanguageDetector).init();

export default i18n;
