import { IPlaceLocation, IPromoter } from "../../types/types";

export const CREATE_EVENT_FETCH_REQUEST = "CREATE_EVENT_FETCH_REQUEST";
export const CREATE_EVENT_FETCH_SUCCESS = "CREATE_EVENT_FETCH_SUCCESS";
export const CREATE_EVENT_FETCH_FAIL = "CREATE_EVENT_FETCH_FAIL";
export const CREATE_EVENT_UPLOAD_IMAGE_FETCH_REQUEST =
  "CREATE_EVENT_UPLOAD_IMAGE_FETCH_REQUEST";
export const CREATE_EVENT_UPLOAD_IMAGE_FETCH_SUCCESS =
  "CREATE_EVENT_UPLOAD_IMAGE_FETCH_SUCCESS";
export const CREATE_EVENT_UPLOAD_IMAGE_FETCH_FAIL =
  "CREATE_EVENT_UPLOAD_IMAGE_FETCH_FAIL";
export const CREATE_EVENT_UPLOAD_VIDEO_FETCH_REQUEST =
  "CREATE_EVENT_UPLOAD_VIDEO_FETCH_REQUEST";
export const CREATE_EVENT_UPLOAD_VIDEO_FETCH_SUCCESS =
  "CREATE_EVENT_UPLOAD_VIDEO_FETCH_SUCCESS";
export const CREATE_EVENT_UPLOAD_VIDEO_FETCH_FAIL =
  "CREATE_EVENT_UPLOAD_VIDEO_FETCH_FAIL";
export const CREATE_EVENT_UPLOAD_IMAGE_RESET =
  "CREATE_EVENT_UPLOAD_IMAGE_RESET";
export const CREATE_EVENT_UPLOAD_VIDEO_RESET =
  "CREATE_EVENT_UPLOAD_VIDEO_RESET";
export const CREATE_EVENT_CREATE_PROMOTER_FETCH_REQUEST =
  "CREATE_EVENT_CREATE_PROMOTER_FETCH_REQUEST";
export const CREATE_EVENT_CREATE_PROMOTER_FETCH_SUCCESS =
  "CREATE_EVENT_CREATE_PROMOTER_FETCH_SUCCESS";
export const CREATE_EVENT_CREATE_PROMOTER_FETCH_FAIL =
  "CREATE_EVENT_CREATE_PROMOTER_FETCH_FAIL";
export const CREATE_EVENT_SUBMIT_FETCH_REQUEST =
  "CREATE_EVENT_SUBMIT_FETCH_REQUEST";
export const CREATE_EVENT_SUBMIT_FETCH_SUCCESS =
  "CREATE_EVENT_SUBMIT_FETCH_SUCCESS";
export const CREATE_EVENT_SUBMIT_FETCH_FAIL = "CREATE_EVENT_SUBMIT_FETCH_FAIL";
export const CREATE_EVENT_SET_EVENT_LOCATION =
  "CREATE_EVENT_SET_EVENT_LOCATION";
export const CREATE_EVENT_SET_SHOW_LOCATION_MODAL =
  "CREATE_EVENT_SET_SHOW_LOCATION_MODAL";
export const CREATE_EVENT_EVENT_LOCATION_MODAL_SET_EVENT_LOCATION =
  "CREATE_EVENT_EVENT_LOCATION_MODAL_SET_EVENT_LOCATION";
export const CREATE_EVENT_LOCATION_MODAL_RESET =
  "CREATE_EVENT_LOCATION_MODAL_RESET";
export const CREATE_EVENT_SET_SHOW_ADDRESS_REQUIRED_MESSAGE =
  "CREATE_EVENT_SET_SHOW_ADDRESS_REQUIRED_MESSAGE";

export const createEventFetchRequest = () => {
  return {
    type: CREATE_EVENT_FETCH_REQUEST,
  };
};

export const createEventFetchSuccess = () => {
  return {
    type: CREATE_EVENT_FETCH_SUCCESS,
  };
};

export const createEventFetchFail = () => {
  return {
    type: CREATE_EVENT_FETCH_FAIL,
  };
};

export const createEventUploadImageFetchRequest = () => {
  return {
    type: CREATE_EVENT_UPLOAD_IMAGE_FETCH_REQUEST,
  };
};

export const createEventUploadImageFetchSuccess = ({
  imageUrl,
}: {
  imageUrl: string;
}) => {
  return {
    type: CREATE_EVENT_UPLOAD_IMAGE_FETCH_SUCCESS,
    payload: imageUrl,
  };
};

export const createEventUploadImageFetchFail = () => {
  return {
    type: CREATE_EVENT_UPLOAD_IMAGE_FETCH_FAIL,
  };
};

export const createEventUploadVideoFetchRequest = () => {
  return {
    type: CREATE_EVENT_UPLOAD_VIDEO_FETCH_REQUEST,
  };
};

export const createEventUploadVideoFetchSuccess = ({
  videoUrl,
}: {
  videoUrl: string;
}) => {
  return {
    type: CREATE_EVENT_UPLOAD_VIDEO_FETCH_SUCCESS,
    payload: videoUrl,
  };
};

export const createEventUploadVideoFetchFail = () => {
  return {
    type: CREATE_EVENT_UPLOAD_VIDEO_FETCH_FAIL,
  };
};

export const createEventUploadImageReset = () => {
  return {
    type: CREATE_EVENT_UPLOAD_IMAGE_RESET,
  };
};

export const createEventUploadVideoReset = () => {
  return {
    type: CREATE_EVENT_UPLOAD_VIDEO_RESET,
  };
};

export const createEventCreatePromoterFetchRequest = () => {
  return {
    type: CREATE_EVENT_CREATE_PROMOTER_FETCH_REQUEST,
  };
};

export const createEventCreatePromoterFetchSuccess = ({
  promoter,
}: {
  promoter: IPromoter;
}) => {
  return {
    type: CREATE_EVENT_CREATE_PROMOTER_FETCH_SUCCESS,
    payload: promoter,
  };
};

export const createEventCreatePromoterFetchFail = () => {
  return {
    type: CREATE_EVENT_CREATE_PROMOTER_FETCH_FAIL,
  };
};

export const createEventSubmitFetchRequest = () => {
  return {
    type: CREATE_EVENT_SUBMIT_FETCH_REQUEST,
  };
};

export const createEventSubmitFetchSuccess = () => {
  return {
    type: CREATE_EVENT_SUBMIT_FETCH_SUCCESS,
  };
};

export const createEventSubmitFetchFail = () => {
  return {
    type: CREATE_EVENT_SUBMIT_FETCH_FAIL,
  };
};

export const createEventSetEventLocation = ({
  location,
}: {
  location: IPlaceLocation;
}) => {
  return {
    type: CREATE_EVENT_SET_EVENT_LOCATION,
    payload: location,
  };
};

export const createEventSetShowLocationModal = ({
  showModal,
}: {
  showModal: boolean;
}) => {
  return {
    type: CREATE_EVENT_SET_SHOW_LOCATION_MODAL,
    payload: showModal,
  };
};

export const createEventLocationModalSetEventLocation = ({
  eventLocation,
}: {
  eventLocation: IPlaceLocation;
}) => {
  return {
    type: CREATE_EVENT_EVENT_LOCATION_MODAL_SET_EVENT_LOCATION,
    payload: eventLocation,
  };
};

export const createEventLocationModalReset = () => {
  return {
    type: CREATE_EVENT_LOCATION_MODAL_RESET,
  };
};

export const createEventSetShowAddressRequiredMessage = ({
  showAddressRequiredMessage,
}: {
  showAddressRequiredMessage: boolean;
}) => {
  return {
    type: CREATE_EVENT_SET_SHOW_ADDRESS_REQUIRED_MESSAGE,
    payload: showAddressRequiredMessage,
  };
};
