import { IAction, IPlaceLocation, IPromoter } from "../../types/types";
import {
  CREATE_EVENT_CREATE_PROMOTER_FETCH_FAIL,
  CREATE_EVENT_CREATE_PROMOTER_FETCH_REQUEST,
  CREATE_EVENT_CREATE_PROMOTER_FETCH_SUCCESS,
  CREATE_EVENT_EVENT_LOCATION_MODAL_SET_EVENT_LOCATION,
  CREATE_EVENT_FETCH_FAIL,
  CREATE_EVENT_FETCH_REQUEST,
  CREATE_EVENT_FETCH_SUCCESS,
  CREATE_EVENT_LOCATION_MODAL_RESET,
  CREATE_EVENT_SET_EVENT_LOCATION,
  CREATE_EVENT_SET_SHOW_ADDRESS_REQUIRED_MESSAGE,
  CREATE_EVENT_SET_SHOW_LOCATION_MODAL,
  CREATE_EVENT_SUBMIT_FETCH_FAIL,
  CREATE_EVENT_SUBMIT_FETCH_REQUEST,
  CREATE_EVENT_SUBMIT_FETCH_SUCCESS,
  CREATE_EVENT_UPLOAD_IMAGE_FETCH_FAIL,
  CREATE_EVENT_UPLOAD_IMAGE_FETCH_REQUEST,
  CREATE_EVENT_UPLOAD_IMAGE_FETCH_SUCCESS,
  CREATE_EVENT_UPLOAD_IMAGE_RESET,
  CREATE_EVENT_UPLOAD_VIDEO_FETCH_FAIL,
  CREATE_EVENT_UPLOAD_VIDEO_FETCH_REQUEST,
  CREATE_EVENT_UPLOAD_VIDEO_FETCH_SUCCESS,
  CREATE_EVENT_UPLOAD_VIDEO_RESET,
} from "../actions/createEvent.actions";

export interface IState {
  eventLocation: IPlaceLocation | null;
  form: {
    inputs: {
      address: {
        showAddressRequiredMessage: boolean;
      };
    };
  };
  requests: {
    createEvent: {
      isLoading: boolean;
      isError: boolean;
      isSuccess: boolean;
    };
    uploadImage: {
      isLoading: boolean;
      isError: boolean;
      imageUrl: string | null;
    };
    uploadVideo: {
      isLoading: boolean;
      isError: boolean;
      videoUrl: string | null;
    };
    createPromoter: {
      isLoading: boolean;
      isError: boolean;
      promoter: IPromoter | null;
    };
    submitCreateEvent: {
      isLoading: boolean;
      isError: boolean;
      isSuccess: boolean;
    };
  };
  modals: {
    eventLocation: {
      showModal: boolean;
      eventLocation: IPlaceLocation | null;
    };
  };
}

const initialState: IState = {
  eventLocation: null,
  form: {
    inputs: {
      address: {
        showAddressRequiredMessage: false,
      },
    },
  },
  requests: {
    createEvent: {
      isLoading: false,
      isError: false,
      isSuccess: false,
    },
    uploadImage: {
      isLoading: false,
      isError: false,
      imageUrl: null,
    },
    uploadVideo: {
      isLoading: false,
      isError: false,
      videoUrl: null,
    },
    createPromoter: {
      isLoading: false,
      isError: false,
      promoter: null,
    },
    submitCreateEvent: {
      isLoading: false,
      isError: false,
      isSuccess: false,
    },
  },
  modals: {
    eventLocation: {
      showModal: false,
      eventLocation: null,
    },
  },
};

export const reducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case CREATE_EVENT_FETCH_REQUEST:
      return {
        ...state,
        requests: {
          ...state.requests,
          createEvent: {
            ...state.requests.createEvent,
            isLoading: true,
            isError: false,
          },
        },
      };

    case CREATE_EVENT_FETCH_SUCCESS:
      return {
        ...state,
        requests: {
          ...state.requests,
          createEvent: {
            ...state.requests.createEvent,
            isLoading: false,
            isSuccess: true,
          },
        },
      };

    case CREATE_EVENT_FETCH_FAIL:
      return {
        ...state,
        requests: {
          ...state.requests,
          createEvent: {
            ...state.requests.createEvent,
            isLoading: false,
            isError: true,
          },
        },
      };

    case CREATE_EVENT_UPLOAD_IMAGE_FETCH_REQUEST:
      return {
        ...state,
        requests: {
          ...state.requests,
          uploadImage: {
            ...state.requests.uploadImage,
            isLoading: true,
            isError: false,
          },
        },
      };

    case CREATE_EVENT_UPLOAD_IMAGE_FETCH_SUCCESS:
      return {
        ...state,
        requests: {
          ...state.requests,
          uploadImage: {
            ...state.requests.uploadImage,
            isLoading: false,
            imageUrl: action.payload,
          },
        },
      };

    case CREATE_EVENT_UPLOAD_IMAGE_FETCH_FAIL:
      return {
        ...state,
        requests: {
          ...state.requests,
          uploadImage: {
            ...state.requests.uploadImage,
            isLoading: false,
            isError: true,
          },
        },
      };

    case CREATE_EVENT_UPLOAD_VIDEO_FETCH_REQUEST:
      return {
        ...state,
        requests: {
          ...state.requests,
          uploadVideo: {
            ...state.requests.uploadVideo,
            isLoading: true,
            isError: false,
          },
        },
      };

    case CREATE_EVENT_UPLOAD_VIDEO_FETCH_SUCCESS:
      return {
        ...state,
        requests: {
          ...state.requests,
          uploadVideo: {
            ...state.requests.uploadVideo,
            isLoading: false,
            videoUrl: action.payload,
          },
        },
      };

    case CREATE_EVENT_UPLOAD_VIDEO_FETCH_FAIL:
      return {
        ...state,
        requests: {
          ...state.requests,
          uploadVideo: {
            ...state.requests.uploadVideo,
            isLoading: false,
            isError: true,
          },
        },
      };

    case CREATE_EVENT_UPLOAD_IMAGE_RESET:
      return {
        ...state,
        requests: {
          ...state.requests,
          uploadImage: {
            ...initialState.requests.uploadImage,
          },
        },
      };

    case CREATE_EVENT_UPLOAD_VIDEO_RESET:
      return {
        ...state,
        requests: {
          ...state.requests,
          uploadVideo: {
            ...initialState.requests.uploadVideo,
          },
        },
      };

    case CREATE_EVENT_CREATE_PROMOTER_FETCH_REQUEST:
      return {
        ...state,
        requests: {
          ...state.requests,
          createPromoter: {
            ...state.requests.createPromoter,
            isLoading: true,
            isError: false,
          },
        },
      };

    case CREATE_EVENT_CREATE_PROMOTER_FETCH_SUCCESS:
      return {
        ...state,
        requests: {
          ...state.requests,
          createPromoter: {
            ...state.requests.createPromoter,
            isLoading: false,
            promoter: action.payload,
          },
        },
      };

    case CREATE_EVENT_CREATE_PROMOTER_FETCH_FAIL:
      return {
        ...state,
        requests: {
          ...state.requests,
          createPromoter: {
            ...state.requests.createPromoter,
            isLoading: false,
            isError: true,
          },
        },
      };

    case CREATE_EVENT_SUBMIT_FETCH_REQUEST:
      return {
        ...state,
        requests: {
          ...state.requests,
          submitCreateEvent: {
            ...state.requests.submitCreateEvent,
            isLoading: true,
            isError: false,
          },
        },
      };

    case CREATE_EVENT_SUBMIT_FETCH_SUCCESS:
      return {
        ...state,
        requests: {
          ...state.requests,
          submitCreateEvent: {
            ...state.requests.submitCreateEvent,
            isLoading: false,
            isSuccess: true,
          },
        },
      };

    case CREATE_EVENT_SUBMIT_FETCH_FAIL:
      return {
        ...state,
        requests: {
          ...state.requests,
          submitCreateEvent: {
            ...state.requests.submitCreateEvent,
            isLoading: false,
            isError: true,
          },
        },
      };

    case CREATE_EVENT_SET_EVENT_LOCATION:
      return {
        ...state,
        eventLocation: action.payload,
      };

    case CREATE_EVENT_SET_SHOW_LOCATION_MODAL:
      return {
        ...state,
        modals: {
          ...state.modals,
          eventLocation: {
            ...state.modals.eventLocation,
            showModal: action.payload,
          },
        },
      };

    case CREATE_EVENT_EVENT_LOCATION_MODAL_SET_EVENT_LOCATION:
      return {
        ...state,
        modals: {
          ...state.modals,
          eventLocation: {
            ...state.modals.eventLocation,
            eventLocation: action.payload,
          },
        },
      };

    case CREATE_EVENT_LOCATION_MODAL_RESET:
      return {
        ...state,
        modals: {
          ...state.modals,
          eventLocation: {
            ...initialState.modals.eventLocation,
          },
        },
      };

    case CREATE_EVENT_SET_SHOW_ADDRESS_REQUIRED_MESSAGE:
      return {
        ...state,
        form: {
          ...state.form,
          inputs: {
            address: {
              ...state.form.inputs.address,
              showAddressRequiredMessage: action.payload,
            },
          },
        },
      };

    default:
      return state;
  }
};
