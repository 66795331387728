import { IPlaceLocation } from "../../types/types";

export const CREATE_CLUB_FETCH_REQUEST = "CREATE_CLUB_FETCH_REQUEST";
export const CREATE_CLUB_FETCH_SUCCESS = "CREATE_CLUB_FETCH_SUCCESS";
export const CREATE_CLUB_FETCH_FAIL = "CREATE_CLUB_FETCH_FAIL";
export const CREATE_CLUB_UPLOAD_IMAGE_FETCH_REQUEST =
  "CREATE_CLUB_UPLOAD_IMAGE_FETCH_REQUEST";
export const CREATE_CLUB_UPLOAD_IMAGE_FETCH_SUCCESS =
  "CREATE_CLUB_UPLOAD_IMAGE_FETCH_SUCCESS";
export const CREATE_CLUB_UPLOAD_IMAGE_FETCH_FAIL =
  "CREATE_CLUB_UPLOAD_IMAGE_FETCH_FAIL";
export const CREATE_CLUB_UPLOAD_VIDEO_FETCH_REQUEST =
  "CREATE_CLUB_UPLOAD_VIDEO_FETCH_REQUEST";
export const CREATE_CLUB_UPLOAD_VIDEO_FETCH_SUCCESS =
  "CREATE_CLUB_UPLOAD_VIDEO_FETCH_SUCCESS";
export const CREATE_CLUB_UPLOAD_VIDEO_FETCH_FAIL =
  "CREATE_CLUB_UPLOAD_VIDEO_FETCH_FAIL";
export const CREATE_CLUB_UPLOAD_IMAGE_RESET = "CREATE_CLUB_UPLOAD_IMAGE_RESET";
export const CREATE_CLUB_UPLOAD_VIDEO_RESET = "CREATE_CLUB_UPLOAD_VIDEO_RESET";
export const CREATE_CLUB_UPLOAD_LOGO_IMAGE_RESET =
  "CREATE_CLUB_UPLOAD_LOGO_IMAGE_RESET";
export const CREATE_CLUB_SUBMIT_FETCH_REQUEST =
  "CREATE_CLUB_SUBMIT_FETCH_REQUEST";
export const CREATE_CLUB_SUBMIT_FETCH_SUCCESS =
  "CREATE_CLUB_SUBMIT_FETCH_SUCCESS";
export const CREATE_CLUB_SUBMIT_FETCH_FAIL = "CREATE_CLUB_SUBMIT_FETCH_FAIL";
export const CREATE_CLUB_UPLOAD_LOGO_IMAGE_FETCH_REQUEST =
  "CREATE_CLUB_UPLOAD_LOGO_IMAGE_FETCH_REQUEST";
export const CREATE_CLUB_UPLOAD_LOGO_IMAGE_FETCH_SUCCESS =
  "CREATE_CLUB_UPLOAD_LOGO_IMAGE_FETCH_SUCCESS";
export const CREATE_CLUB_UPLOAD_LOGO_IMAGE_FETCH_FAIL =
  "CREATE_CLUB_UPLOAD_LOGO_IMAGE_FETCH_FAIL";
export const CREATE_CLUB_SET_CLUB_LOCATION = "CREATE_CLUB_SET_CLUB_LOCATION";
export const CREATE_CLUB_SET_SHOW_LOCATION_MODAL =
  "CREATE_CLUB_SET_SHOW_LOCATION_MODAL";
export const CREATE_CLUB_CLUB_LOCATION_MODAL_SET_CLUB_LOCATION =
  "CREATE_CLUB_CLUB_LOCATION_MODAL_SET_CLUB_LOCATION";
export const CREATE_CLUB_LOCATION_MODAL_RESET =
  "CREATE_CLUB_LOCATION_MODAL_RESET";
export const CREATE_CLUB_SET_SHOW_ADDRESS_REQUIRED_MESSAGE =
  "CREATE_CLUB_SET_SHOW_ADDRESS_REQUIRED_MESSAGE";

export const createClubFetchRequest = () => {
  return {
    type: CREATE_CLUB_FETCH_REQUEST,
  };
};

export const createClubFetchSuccess = () => {
  return {
    type: CREATE_CLUB_FETCH_SUCCESS,
  };
};

export const createClubFetchFail = () => {
  return {
    type: CREATE_CLUB_FETCH_FAIL,
  };
};

export const createClubUploadImageFetchRequest = () => {
  return {
    type: CREATE_CLUB_UPLOAD_IMAGE_FETCH_REQUEST,
  };
};

export const createClubUploadImageFetchSuccess = ({
  imageUrl,
}: {
  imageUrl: string;
}) => {
  return {
    type: CREATE_CLUB_UPLOAD_IMAGE_FETCH_SUCCESS,
    payload: imageUrl,
  };
};

export const createClubUploadImageFetchFail = () => {
  return {
    type: CREATE_CLUB_UPLOAD_IMAGE_FETCH_FAIL,
  };
};

export const createClubUploadVideoFetchRequest = () => {
  return {
    type: CREATE_CLUB_UPLOAD_VIDEO_FETCH_REQUEST,
  };
};

export const createClubUploadVideoFetchSuccess = ({
  videoUrl,
}: {
  videoUrl: string;
}) => {
  return {
    type: CREATE_CLUB_UPLOAD_VIDEO_FETCH_SUCCESS,
    payload: videoUrl,
  };
};

export const createClubUploadVideoFetchFail = () => {
  return {
    type: CREATE_CLUB_UPLOAD_VIDEO_FETCH_FAIL,
  };
};

export const createClubUploadImageReset = () => {
  return {
    type: CREATE_CLUB_UPLOAD_IMAGE_RESET,
  };
};

export const createClubUploadVideoReset = () => {
  return {
    type: CREATE_CLUB_UPLOAD_VIDEO_RESET,
  };
};

export const createClubSubmitFetchRequest = () => {
  return {
    type: CREATE_CLUB_SUBMIT_FETCH_REQUEST,
  };
};

export const createClubSubmitFetchSuccess = () => {
  return {
    type: CREATE_CLUB_SUBMIT_FETCH_SUCCESS,
  };
};

export const createClubSubmitFetchFail = () => {
  return {
    type: CREATE_CLUB_SUBMIT_FETCH_FAIL,
  };
};

export const createClubUploadLogoImageFetchRequest = () => {
  return {
    type: CREATE_CLUB_UPLOAD_LOGO_IMAGE_FETCH_REQUEST,
  };
};

export const createClubUploadLogoImageFetchSuccess = ({
  imageUrl,
}: {
  imageUrl: string;
}) => {
  return {
    type: CREATE_CLUB_UPLOAD_LOGO_IMAGE_FETCH_SUCCESS,
    payload: imageUrl,
  };
};

export const createClubUploadLogoImageFetchFail = () => {
  return {
    type: CREATE_CLUB_UPLOAD_LOGO_IMAGE_FETCH_FAIL,
  };
};

export const createClubUploadLogoImageReset = () => {
  return {
    type: CREATE_CLUB_UPLOAD_LOGO_IMAGE_RESET,
  };
};

export const createClubSetClubLocation = ({
  location,
}: {
  location: IPlaceLocation;
}) => {
  return {
    type: CREATE_CLUB_SET_CLUB_LOCATION,
    payload: location,
  };
};

export const createClubSetShowLocationModal = ({
  showModal,
}: {
  showModal: boolean;
}) => {
  return {
    type: CREATE_CLUB_SET_SHOW_LOCATION_MODAL,
    payload: showModal,
  };
};

export const createClubLocationModalSetClubLocation = ({
  clubLocation,
}: {
  clubLocation: IPlaceLocation;
}) => {
  return {
    type: CREATE_CLUB_CLUB_LOCATION_MODAL_SET_CLUB_LOCATION,
    payload: clubLocation,
  };
};

export const createClubLocationModalReset = () => {
  return {
    type: CREATE_CLUB_LOCATION_MODAL_RESET,
  };
};

export const createClubSetShowAddressRequiredMessage = ({
  showAddressRequiredMessage,
}: {
  showAddressRequiredMessage: boolean;
}) => {
  return {
    type: CREATE_CLUB_SET_SHOW_ADDRESS_REQUIRED_MESSAGE,
    payload: showAddressRequiredMessage,
  };
};
