import { IAction } from "../../types/types";
import {
  CREATE_DJ_FETCH_REQUEST,
  CREATE_DJ_FETCH_SUCCESS,
  CREATE_DJ_FETCH_FAIL,
  CREATE_DJ_UPLOAD_PROFILE_IMAGE_FETCH_REQUEST,
  CREATE_DJ_UPLOAD_PROFILE_IMAGE_FETCH_SUCCESS,
  CREATE_DJ_UPLOAD_PROFILE_IMAGE_FETCH_FAIL,
  CREATE_DJ_UPLOAD_VIDEO_FETCH_REQUEST,
  CREATE_DJ_UPLOAD_VIDEO_FETCH_SUCCESS,
  CREATE_DJ_SUBMIT_FETCH_FAIL,
  CREATE_DJ_SUBMIT_FETCH_SUCCESS,
  CREATE_DJ_SUBMIT_FETCH_REQUEST,
  CREATE_DJ_UPLOAD_VIDEO_RESET,
  CREATE_DJ_UPLOAD_PROFILE_IMAGE_RESET,
  CREATE_DJ_UPLOAD_VIDEO_FETCH_FAIL,
} from "../actions/createDj.actions";

export interface IState {
  requests: {
    createDj: {
      isLoading: boolean;
      isError: boolean;
      isSuccess: boolean;
    };
    uploadProfileImage: {
      isLoading: boolean;
      isError: boolean;
      profileImageUrl: string | null;
    };
    uploadVideo: {
      isLoading: boolean;
      isError: boolean;
      videoUrl: string | null;
    };
    submitCreateDj: {
      isLoading: boolean;
      isError: boolean;
      isSuccess: boolean;
    };
  };
}

const initialState: IState = {
  requests: {
    createDj: {
      isLoading: false,
      isError: false,
      isSuccess: false,
    },
    uploadProfileImage: {
      isLoading: false,
      isError: false,
      profileImageUrl: null,
    },
    uploadVideo: {
      isLoading: false,
      isError: false,
      videoUrl: null,
    },
    submitCreateDj: {
      isLoading: false,
      isError: false,
      isSuccess: false,
    },
  },
};

export const reducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case CREATE_DJ_FETCH_REQUEST:
      return {
        ...state,
        requests: {
          ...state.requests,
          createDj: {
            ...state.requests.createDj,
            isLoading: true,
            isError: false,
          },
        },
      };

    case CREATE_DJ_FETCH_SUCCESS:
      return {
        ...state,
        requests: {
          ...state.requests,
          createDj: {
            ...state.requests.createDj,
            isLoading: false,
            isSuccess: true,
          },
        },
      };

    case CREATE_DJ_FETCH_FAIL:
      return {
        ...state,
        requests: {
          ...state.requests,
          createDj: {
            ...state.requests.createDj,
            isLoading: false,
            isError: true,
          },
        },
      };

    case CREATE_DJ_UPLOAD_PROFILE_IMAGE_FETCH_REQUEST:
      return {
        ...state,
        requests: {
          ...state.requests,
          uploadProfileImage: {
            ...state.requests.uploadProfileImage,
            isLoading: true,
            isError: false,
          },
        },
      };

    case CREATE_DJ_UPLOAD_PROFILE_IMAGE_FETCH_SUCCESS:
      return {
        ...state,
        requests: {
          ...state.requests,
          uploadProfileImage: {
            ...state.requests.uploadProfileImage,
            isLoading: false,
            profileImageUrl: action.payload,
          },
        },
      };

    case CREATE_DJ_UPLOAD_PROFILE_IMAGE_FETCH_FAIL:
      return {
        ...state,
        requests: {
          ...state.requests,
          uploadProfileImage: {
            ...state.requests.uploadProfileImage,
            isLoading: false,
            isError: true,
          },
        },
      };

    case CREATE_DJ_UPLOAD_VIDEO_FETCH_SUCCESS:
      return {
        ...state,
        requests: {
          ...state.requests,
          uploadVideo: {
            ...state.requests.uploadVideo,
            isLoading: false,
            videoUrl: action.payload,
          },
        },
      };

    case CREATE_DJ_UPLOAD_VIDEO_FETCH_FAIL:
      return {
        ...state,
        requests: {
          ...state.requests,
          uploadVideo: {
            ...state.requests.uploadVideo,
            isLoading: false,
            isError: true,
          },
        },
      };

    case CREATE_DJ_UPLOAD_PROFILE_IMAGE_RESET:
      return {
        ...state,
        requests: {
          ...state.requests,
          uploadProfileImage: {
            ...initialState.requests.uploadProfileImage,
          },
        },
      };

    case CREATE_DJ_UPLOAD_VIDEO_RESET:
      return {
        ...state,
        requests: {
          ...state.requests,
          uploadVideo: {
            ...initialState.requests.uploadVideo,
          },
        },
      };

    case CREATE_DJ_SUBMIT_FETCH_REQUEST:
      return {
        ...state,
        requests: {
          ...state.requests,
          submitCreateDj: {
            ...state.requests.submitCreateDj,
            isLoading: true,
            isError: false,
          },
        },
      };

    case CREATE_DJ_SUBMIT_FETCH_SUCCESS:
      return {
        ...state,
        requests: {
          ...state.requests,
          submitCreateDj: {
            ...state.requests.submitCreateDj,
            isLoading: false,
            isSuccess: true,
          },
        },
      };

    case CREATE_DJ_SUBMIT_FETCH_FAIL:
      return {
        ...state,
        requests: {
          ...state.requests,
          submitCreateDj: {
            ...state.requests.submitCreateDj,
            isLoading: false,
            isError: true,
          },
        },
      };

    case CREATE_DJ_UPLOAD_VIDEO_FETCH_REQUEST:
      return {
        ...state,
        requests: {
          ...state.requests,
          uploadVideo: {
            ...state.requests.uploadVideo,
            isLoading: true,
            isError: false,
          },
        },
      };

    default:
      return state;
  }
};
