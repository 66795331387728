export const HOME = "/";
export const PRIVACY_POLICY = "/privacy-policy";
export const TERMS_CONDITIONS = "/terms-conditions";
export const CANCEL_TERMS = "/cancel-terms";
export const ACCESSBILITY_POLICY = "/accessibility-policy";
export const ABOUT = "/about";
export const PRODUCER = "/producer";
export const CONTACT_US = "/contact-us";
export const EVENT_DETAILS = "/event/";
export const EVENT_TICKET_CHECKOUT = "/event-ticket/checkout";
export const NOT_FOUND = "*";
export const CREATE_EVENT_APP = "/create-event";
export const CREATE_CLUB = "/create-club";
export const CREATE_DJ = "/create-dj";
export const UPDATE_CLUB = "/update-club";
export const EVENT_SCANNER = "/app/event/scanner";
