export const PRIVACY_POLICY_FETCH_REQUEST = "PRIVACY_POLICY_FETCH_REQUEST";
export const PRIVACY_POLICY_FETCH_SUCCESS = "PRIVACY_POLICY_FETCH_SUCCESS";
export const PRIVACY_POLICY_FETCH_FAIL = "PRIVACY_POLICY_FETCH_FAIL";

export const privacyPolicyFetchRequest = () => {
  return {
    type: PRIVACY_POLICY_FETCH_REQUEST,
  };
};

export const privacyPolicyFetchSuccess = (privacyPolicy: string) => {
  return {
    type: PRIVACY_POLICY_FETCH_SUCCESS,
    payload: privacyPolicy,
  };
};

export const privacyPolicyFetchFail = () => {
  return {
    type: PRIVACY_POLICY_FETCH_FAIL,
  };
};
