export const TERMS_CONDITIONS_FETCH_REQUEST = "TERMS_CONDITIONS_FETCH_REQUEST";
export const TERMS_CONDITIONS_FETCH_SUCCESS = "TERMS_CONDITIONS_FETCH_SUCCESS";
export const TERMS_CONDITIONS_FETCH_FAIL = "TERMS_CONDITIONS_FETCH_FAIL";

export const termsConditionsFetchRequest = () => {
  return {
    type: TERMS_CONDITIONS_FETCH_REQUEST,
  };
};
export const termsConditionsFetchSuccess = (termsConditions: string) => {
  return {
    type: TERMS_CONDITIONS_FETCH_SUCCESS,
    payload: termsConditions,
  };
};
export const termsConditionsFetchFail = () => {
  return {
    type: TERMS_CONDITIONS_FETCH_FAIL,
  };
};
