import { IEventDetails } from "../../types/types";

export const EVENT_DETAILS_SET_INIT_DATA = "EVENT_DETAILS_SET_INIT_DATA";
export const EVENT_DETAILS_FETCH_REQUEST = "EVENT_DETAILS_FETCH_REQUEST";
export const EVENT_DETAILS_FETCH_SUCCESS = "EVENT_DETAILS_FETCH_SUCCESS";
export const EVENT_DETAILS_FETCH_FAIL = "EVENT_DETAILS_FETCH_FAIL";

export const eventDetailsSetInitData = (
  eventId: string,
  eventName: string,
  eventImage: string
) => {
  return {
    type: EVENT_DETAILS_SET_INIT_DATA,
    payload: { eventId, eventName, eventImage },
  };
};

export const eventDetailsFetchRequest = () => {
  return {
    type: EVENT_DETAILS_FETCH_REQUEST,
  };
};

export const eventDetailsFetchSuccess = (event: IEventDetails) => {
  return {
    type: EVENT_DETAILS_FETCH_SUCCESS,
    payload: event,
  };
};

export const eventDetailsFetchFail = () => {
  return {
    type: EVENT_DETAILS_FETCH_FAIL,
  };
};
