import { IEvent } from "../../types/types";

export const HOME_EVENTS_FETCH_REQUEST = "HOME_EVENTS_FETCH_REQUEST";
export const HOME_EVENTS_FETCH_SUCCESS = "HOME_EVENTS_FETCH_SUCCESS";
export const HOME_EVENTS_FETCH_FAIL = "HOME_EVENTS_FETCH_FAIL";

export const homeEventsFetchRequest = () => {
  return {
    type: HOME_EVENTS_FETCH_REQUEST,
  };
};

export const homeEventsFetchSuccess = (events: IEvent[]) => {
  return {
    type: HOME_EVENTS_FETCH_SUCCESS,
    payload: events,
  };
};

export const homeEventsFetchFail = () => {
  return {
    type: HOME_EVENTS_FETCH_FAIL,
  };
};
