export const NODE_ENV = process.env.REACT_APP_ENV as
  | "development"
  | "sandbox"
  | "production";

export const PAYPAL_SANDBOX_CLIENT_ID =
  "AdyVTn_4JwvmMS2SUvWkwPa_iq4c1iJYZkacOUl_ovkbgJpIR81eGtWLjsz5wlg8cdsjJbqjQcCT6KPY";
export const PAYPAL_LIVE_CLIENT_ID =
  "Aa1f4LcaD1mhJPt5IwMLtHwsB0mCavVGWDpvPsN84gplwV3P4DXesxuWFABEyQk9zWnr1h0kiNMeCff0";
export function onGetPayPalClientId(): string {
  switch (NODE_ENV) {
    case "development":
      return PAYPAL_SANDBOX_CLIENT_ID;

    case "sandbox":
      return PAYPAL_SANDBOX_CLIENT_ID;

    case "production":
      return PAYPAL_LIVE_CLIENT_ID;
  }
}
export const PAYPAL_CLIENT_ID = onGetPayPalClientId();

export const GOOGLE_MAP_API_KEY = "AIzaSyDOiy8vcjA2ddKTfhdgZWK3JgI7TnhBauM";
