import { IAction, IClub } from "../../types/types";
import {
  UPDATE_CLUB_FETCH_FAIL,
  UPDATE_CLUB_FETCH_REQUEST,
  UPDATE_CLUB_FETCH_SUCCESS,
  UPDATE_CLUB_SEARCH_CLUB_FETCH_FAIL,
  UPDATE_CLUB_SEARCH_CLUB_FETCH_REQUEST,
  UPDATE_CLUB_SEARCH_CLUB_FETCH_SUCCESS,
  UPDATE_CLUB_SEARCH_SET_SEARCH_INPUT,
  UPDATE_CLUB_SEND_EMAIL_VERIFICATION_CODE_FETCH_FAIL,
  UPDATE_CLUB_SEND_EMAIL_VERIFICATION_CODE_FETCH_REQUEST,
  UPDATE_CLUB_SEND_EMAIL_VERIFICATION_CODE_FETCH_SUCCESS,
  UPDATE_CLUB_SET_CHOSEN_CLUB,
  UPDATE_CLUB_UPLOAD_IMAGE_FETCH_FAIL,
  UPDATE_CLUB_UPLOAD_IMAGE_FETCH_REQUEST,
  UPDATE_CLUB_UPLOAD_IMAGE_FETCH_SUCCESS,
  UPDATE_CLUB_UPLOAD_IMAGE_RESET,
  UPDATE_CLUB_UPLOAD_LOGO_IMAGE_FETCH_FAIL,
  UPDATE_CLUB_UPLOAD_LOGO_IMAGE_FETCH_REQUEST,
  UPDATE_CLUB_UPLOAD_LOGO_IMAGE_FETCH_SUCCESS,
  UPDATE_CLUB_UPLOAD_LOGO_IMAGE_RESET,
  UPDATE_CLUB_UPLOAD_VIDEO_FETCH_FAIL,
  UPDATE_CLUB_UPLOAD_VIDEO_FETCH_REQUEST,
  UPDATE_CLUB_UPLOAD_VIDEO_FETCH_SUCCESS,
  UPDATE_CLUB_UPLOAD_VIDEO_RESET,
} from "../actions/updateClub.actions";

export interface IState {
  chosenClub: IClub | null;
  requests: {
    searchClub: {
      searchInput: string;
      isLoading: boolean;
      isError: boolean;
      clubs: IClub[];
    };
    sendEmailVerificationCode: {
      isLoading: boolean;
      isError: boolean;
      isSuccess: boolean;
    };
    uploadImage: {
      isLoading: boolean;
      isError: boolean;
      imageUrl: string | null;
    };
    uploadLogoImage: {
      isLoading: boolean;
      isError: boolean;
      imageUrl: string | null;
    };
    uploadVideo: {
      isLoading: boolean;
      isError: boolean;
      videoUrl: string | null;
    };
    updateClub: {
      isLoading: boolean;
      isError: boolean;
      isSuccess: boolean;
    };
  };
}

const initialState: IState = {
  chosenClub: null,
  requests: {
    searchClub: {
      searchInput: "",
      isLoading: false,
      isError: false,
      clubs: [],
    },
    sendEmailVerificationCode: {
      isLoading: false,
      isError: false,
      isSuccess: false,
    },
    uploadImage: {
      isLoading: false,
      isError: false,
      imageUrl: null,
    },
    uploadLogoImage: {
      isLoading: false,
      isError: false,
      imageUrl: null,
    },
    uploadVideo: {
      isLoading: false,
      isError: false,
      videoUrl: null,
    },
    updateClub: {
      isLoading: false,
      isError: false,
      isSuccess: false,
    },
  },
};

export const reducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case UPDATE_CLUB_SEARCH_CLUB_FETCH_REQUEST:
      return {
        ...state,
        requests: {
          ...state.requests,
          searchClub: {
            ...state.requests.searchClub,
            isLoading: true,
            isError: false,
          },
        },
      };

    case UPDATE_CLUB_SEARCH_CLUB_FETCH_SUCCESS:
      return {
        ...state,
        requests: {
          ...state.requests,
          searchClub: {
            ...state.requests.searchClub,
            isLoading: false,
            clubs: action.payload,
          },
        },
      };

    case UPDATE_CLUB_SEARCH_CLUB_FETCH_FAIL:
      return {
        ...state,
        requests: {
          ...state.requests,
          searchClub: {
            ...state.requests.searchClub,
            isLoading: false,
            isError: true,
          },
        },
      };

    case UPDATE_CLUB_SET_CHOSEN_CLUB:
      return {
        ...state,
        chosenClub: action.payload,
      };

    case UPDATE_CLUB_SEARCH_SET_SEARCH_INPUT:
      return {
        ...state,
        requests: {
          ...state.requests,
          searchClub: {
            ...state.requests.searchClub,
            searchInput: action.payload,
          },
        },
      };

    case UPDATE_CLUB_SEND_EMAIL_VERIFICATION_CODE_FETCH_REQUEST:
      return {
        ...state,
        requests: {
          ...state.requests,
          sendEmailVerificationCode: {
            ...state.requests.sendEmailVerificationCode,
            isLoading: true,
            isError: false,
          },
        },
      };

    case UPDATE_CLUB_SEND_EMAIL_VERIFICATION_CODE_FETCH_SUCCESS:
      return {
        ...state,
        requests: {
          ...state.requests,
          sendEmailVerificationCode: {
            ...state.requests.sendEmailVerificationCode,
            isLoading: false,
            isSuccess: true,
          },
        },
      };

    case UPDATE_CLUB_SEND_EMAIL_VERIFICATION_CODE_FETCH_FAIL:
      return {
        ...state,
        requests: {
          ...state.requests,
          sendEmailVerificationCode: {
            ...state.requests.sendEmailVerificationCode,
            isLoading: false,
            isError: true,
          },
        },
      };

    case UPDATE_CLUB_UPLOAD_IMAGE_FETCH_REQUEST:
      return {
        ...state,
        requests: {
          ...state.requests,
          uploadImage: {
            ...state.requests.uploadImage,
            isLoading: true,
            isError: false,
          },
        },
      };

    case UPDATE_CLUB_UPLOAD_IMAGE_FETCH_SUCCESS:
      return {
        ...state,
        requests: {
          ...state.requests,
          uploadImage: {
            ...state.requests.uploadImage,
            isLoading: false,
            imageUrl: action.payload,
          },
        },
      };

    case UPDATE_CLUB_UPLOAD_IMAGE_FETCH_FAIL:
      return {
        ...state,
        requests: {
          ...state.requests,
          uploadImage: {
            ...state.requests.uploadImage,
            isLoading: false,
            isError: true,
          },
        },
      };

    case UPDATE_CLUB_UPLOAD_VIDEO_FETCH_REQUEST:
      return {
        ...state,
        requests: {
          ...state.requests,
          uploadVideo: {
            ...state.requests.uploadVideo,
            isLoading: true,
            isError: false,
          },
        },
      };

    case UPDATE_CLUB_UPLOAD_VIDEO_FETCH_SUCCESS:
      return {
        ...state,
        requests: {
          ...state.requests,
          uploadVideo: {
            ...state.requests.uploadVideo,
            isLoading: false,
            videoUrl: action.payload,
          },
        },
      };

    case UPDATE_CLUB_UPLOAD_VIDEO_FETCH_FAIL:
      return {
        ...state,
        requests: {
          ...state.requests,
          uploadVideo: {
            ...state.requests.uploadVideo,
            isLoading: false,
            isError: true,
          },
        },
      };

    case UPDATE_CLUB_UPLOAD_IMAGE_RESET:
      return {
        ...state,
        requests: {
          ...state.requests,
          uploadImage: {
            ...initialState.requests.uploadImage,
          },
        },
      };

    case UPDATE_CLUB_UPLOAD_VIDEO_RESET:
      return {
        ...state,
        requests: {
          ...state.requests,
          uploadVideo: {
            ...initialState.requests.uploadVideo,
          },
        },
      };
    case UPDATE_CLUB_UPLOAD_LOGO_IMAGE_FETCH_REQUEST:
      return {
        ...state,
        requests: {
          ...state.requests,
          uploadLogoImage: {
            ...state.requests.uploadLogoImage,
            isLoading: true,
            isError: false,
          },
        },
      };

    case UPDATE_CLUB_UPLOAD_LOGO_IMAGE_FETCH_SUCCESS:
      return {
        ...state,
        requests: {
          ...state.requests,
          uploadLogoImage: {
            ...state.requests.uploadLogoImage,
            isLoading: false,
            imageUrl: action.payload,
          },
        },
      };

    case UPDATE_CLUB_UPLOAD_LOGO_IMAGE_FETCH_FAIL:
      return {
        ...state,
        requests: {
          ...state.requests,
          uploadLogoImage: {
            ...state.requests.uploadLogoImage,
            isLoading: false,
            isError: true,
          },
        },
      };

    case UPDATE_CLUB_UPLOAD_LOGO_IMAGE_RESET:
      return {
        ...state,
        requests: {
          ...state.requests,
          uploadLogoImage: {
            ...initialState.requests.uploadLogoImage,
          },
        },
      };

    case UPDATE_CLUB_FETCH_REQUEST:
      return {
        ...state,
        requests: {
          ...state.requests,
          updateClub: {
            ...state.requests.updateClub,
            isLoading: true,
            isError: false,
          },
        },
      };

    case UPDATE_CLUB_FETCH_SUCCESS:
      return {
        ...state,
        requests: {
          ...state.requests,
          updateClub: {
            ...state.requests.updateClub,
            isLoading: false,
            isSuccess: true,
          },
        },
      };

    case UPDATE_CLUB_FETCH_FAIL:
      return {
        ...state,
        requests: {
          ...state.requests,
          updateClub: {
            ...state.requests.updateClub,
            isLoading: false,
            isError: true,
          },
        },
      };

    default:
      return state;
  }
};
