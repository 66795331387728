import { IClub } from "../../types/types";

export const UPDATE_CLUB_SEARCH_CLUB_FETCH_REQUEST =
  "UPDATE_CLUB_SEARCH_CLUB_FETCH_REQUEST";
export const UPDATE_CLUB_SEARCH_CLUB_FETCH_SUCCESS =
  "UPDATE_CLUB_SEARCH_CLUB_FETCH_SUCCESS";
export const UPDATE_CLUB_SEARCH_CLUB_FETCH_FAIL =
  "UPDATE_CLUB_SEARCH_CLUB_FETCH_FAIL";
export const UPDATE_CLUB_SET_CHOSEN_CLUB = "UPDATE_CLUB_SET_CHOSEN_CLUB";
export const UPDATE_CLUB_SEARCH_SET_SEARCH_INPUT =
  "UPDATE_CLUB_SEARCH_SET_SEARCH_INPUT";
export const UPDATE_CLUB_SEND_EMAIL_VERIFICATION_CODE_FETCH_REQUEST =
  "UPDATE_CLUB_SEND_EMAIL_VERIFICATION_CODE_FETCH_REQUEST";
export const UPDATE_CLUB_SEND_EMAIL_VERIFICATION_CODE_FETCH_SUCCESS =
  "UPDATE_CLUB_SEND_EMAIL_VERIFICATION_CODE_FETCH_SUCCESS";
export const UPDATE_CLUB_SEND_EMAIL_VERIFICATION_CODE_FETCH_FAIL =
  "UPDATE_CLUB_SEND_EMAIL_VERIFICATION_CODE_FETCH_FAIL";
export const UPDATE_CLUB_UPLOAD_IMAGE_FETCH_REQUEST =
  "UPDATE_CLUB_UPLOAD_IMAGE_FETCH_REQUEST";
export const UPDATE_CLUB_UPLOAD_IMAGE_FETCH_SUCCESS =
  "UPDATE_CLUB_UPLOAD_IMAGE_FETCH_SUCCESS";
export const UPDATE_CLUB_UPLOAD_IMAGE_FETCH_FAIL =
  "UPDATE_CLUB_UPLOAD_IMAGE_FETCH_FAIL";
export const UPDATE_CLUB_UPLOAD_VIDEO_FETCH_REQUEST =
  "UPDATE_CLUB_UPLOAD_VIDEO_FETCH_REQUEST";
export const UPDATE_CLUB_UPLOAD_VIDEO_FETCH_SUCCESS =
  "UPDATE_CLUB_UPLOAD_VIDEO_FETCH_SUCCESS";
export const UPDATE_CLUB_UPLOAD_VIDEO_FETCH_FAIL =
  "UPDATE_CLUB_UPLOAD_VIDEO_FETCH_FAIL";
export const UPDATE_CLUB_UPLOAD_IMAGE_RESET = "UPDATE_CLUB_UPLOAD_IMAGE_RESET";
export const UPDATE_CLUB_UPLOAD_VIDEO_RESET = "UPDATE_CLUB_UPLOAD_VIDEO_RESET";
export const UPDATE_CLUB_UPLOAD_LOGO_IMAGE_RESET =
  "UPDATE_CLUB_UPLOAD_LOGO_IMAGE_RESET";
export const UPDATE_CLUB_UPLOAD_LOGO_IMAGE_FETCH_REQUEST =
  "UPDATE_CLUB_UPLOAD_LOGO_IMAGE_FETCH_REQUEST";
export const UPDATE_CLUB_UPLOAD_LOGO_IMAGE_FETCH_SUCCESS =
  "UPDATE_CLUB_UPLOAD_LOGO_IMAGE_FETCH_SUCCESS";
export const UPDATE_CLUB_UPLOAD_LOGO_IMAGE_FETCH_FAIL =
  "UPDATE_CLUB_UPLOAD_LOGO_IMAGE_FETCH_FAIL";
export const UPDATE_CLUB_FETCH_REQUEST = "UPDATE_CLUB_FETCH_REQUEST";
export const UPDATE_CLUB_FETCH_SUCCESS = "UPDATE_CLUB_FETCH_SUCCESS";
export const UPDATE_CLUB_FETCH_FAIL = "UPDATE_CLUB_FETCH_FAIL";

export const updateClubSearchClubFetchRequest = () => {
  return {
    type: UPDATE_CLUB_SEARCH_CLUB_FETCH_REQUEST,
  };
};

export const updateClubSearchClubFetchSuccess = ({
  clubs,
}: {
  clubs: IClub[];
}) => {
  return {
    type: UPDATE_CLUB_SEARCH_CLUB_FETCH_SUCCESS,
    payload: clubs,
  };
};

export const updateClubSearchClubFetchFail = () => {
  return {
    type: UPDATE_CLUB_SEARCH_CLUB_FETCH_FAIL,
  };
};

export const updateClubSetChosenClub = ({ club }: { club: IClub }) => {
  return {
    type: UPDATE_CLUB_SET_CHOSEN_CLUB,
    payload: club,
  };
};

export const updateClubSearchSetSearchInput = ({
  searchInput,
}: {
  searchInput: string;
}) => {
  return {
    type: UPDATE_CLUB_SEARCH_SET_SEARCH_INPUT,
    payload: searchInput,
  };
};

export const updateClubSendEmailVerificationCodeFetchRequest = () => {
  return {
    type: UPDATE_CLUB_SEND_EMAIL_VERIFICATION_CODE_FETCH_REQUEST,
  };
};

export const updateClubSendEmailVerificationCodeFetchSuccess = () => {
  return {
    type: UPDATE_CLUB_SEND_EMAIL_VERIFICATION_CODE_FETCH_SUCCESS,
  };
};

export const updateClubSendEmailVerificationCodeFetchFail = () => {
  return {
    type: UPDATE_CLUB_SEND_EMAIL_VERIFICATION_CODE_FETCH_FAIL,
  };
};

export const updateClubUploadImageFetchRequest = () => {
  return {
    type: UPDATE_CLUB_UPLOAD_IMAGE_FETCH_REQUEST,
  };
};

export const updateClubUploadImageFetchSuccess = ({
  imageUrl,
}: {
  imageUrl: string;
}) => {
  return {
    type: UPDATE_CLUB_UPLOAD_IMAGE_FETCH_SUCCESS,
    payload: imageUrl,
  };
};

export const updateClubUploadImageFetchFail = () => {
  return {
    type: UPDATE_CLUB_UPLOAD_IMAGE_FETCH_FAIL,
  };
};

export const updateClubUploadVideoFetchRequest = () => {
  return {
    type: UPDATE_CLUB_UPLOAD_VIDEO_FETCH_REQUEST,
  };
};

export const updateClubUploadVideoFetchSuccess = ({
  videoUrl,
}: {
  videoUrl: string;
}) => {
  return {
    type: UPDATE_CLUB_UPLOAD_VIDEO_FETCH_SUCCESS,
    payload: videoUrl,
  };
};

export const updateClubUploadVideoFetchFail = () => {
  return {
    type: UPDATE_CLUB_UPLOAD_VIDEO_FETCH_FAIL,
  };
};

export const updateClubUploadImageReset = () => {
  return {
    type: UPDATE_CLUB_UPLOAD_IMAGE_RESET,
  };
};

export const updateClubUploadVideoReset = () => {
  return {
    type: UPDATE_CLUB_UPLOAD_VIDEO_RESET,
  };
};

export const updateClubUploadLogoImageFetchRequest = () => {
  return {
    type: UPDATE_CLUB_UPLOAD_LOGO_IMAGE_FETCH_REQUEST,
  };
};

export const updateClubUploadLogoImageFetchSuccess = ({
  imageUrl,
}: {
  imageUrl: string;
}) => {
  return {
    type: UPDATE_CLUB_UPLOAD_LOGO_IMAGE_FETCH_SUCCESS,
    payload: imageUrl,
  };
};

export const updateClubUploadLogoImageFetchFail = () => {
  return {
    type: UPDATE_CLUB_UPLOAD_LOGO_IMAGE_FETCH_FAIL,
  };
};

export const updateClubUploadLogoImageReset = () => {
  return {
    type: UPDATE_CLUB_UPLOAD_LOGO_IMAGE_RESET,
  };
};

export const updateClubFetchRequest = () => {
  return {
    type: UPDATE_CLUB_FETCH_REQUEST,
  };
};

export const updateClubFetchSuccess = () => {
  return {
    type: UPDATE_CLUB_FETCH_SUCCESS,
  };
};

export const updateClubFetchFail = () => {
  return {
    type: UPDATE_CLUB_FETCH_FAIL,
  };
};
