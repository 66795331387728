import { combineReducers } from "redux";
import { reducer as navReducer } from "./nav.reducer";
import { reducer as homeReducer } from "./home.reducer";
import { reducer as eventDetailsReducer } from "./eventDetails.reducer";
import { reducer as privacyPolicyReducer } from "./privacyPolicy.reducer";
import { reducer as termsConditionsReducer } from "./termsConditions.reducer";
import { reducer as createEventReducer } from "./createEvent.reducer";
import { reducer as createClub } from "./createClub.reducer";
import { reducer as createDjReducer } from "./createDj.reducer";
import { reducer as updateClubReducer } from "./updateClub.reducer";
import { reducer as eventTicketCheckoutReducer } from "./eventTicketCheckout.reducer";

export default combineReducers({
  nav: navReducer,
  homeScreen: homeReducer,
  eventDetailsScreen: eventDetailsReducer,
  privacyPolicy: privacyPolicyReducer,
  termsConditions: termsConditionsReducer,
  createEvent: createEventReducer,
  createClub: createClub,
  createDj: createDjReducer,
  updateClub: updateClubReducer,
  eventTicketCheckout: eventTicketCheckoutReducer,
});
