import { IAction, IEventTicket } from "../../types/types";
import {
  EVENT_TICKET_CHECKOUT_EVENT_TICKET_FETCH_FAIL,
  EVENT_TICKET_CHECKOUT_EVENT_TICKET_FETCH_REQUEST,
  EVENT_TICKET_CHECKOUT_EVENT_TICKET_FETCH_SUCCESS,
  EVENT_TICKET_CHECKOUT_SET_EVENT_TICKET_COUPON_CODE,
  EVENT_TICKET_CHECKOUT_SET_IS_LOADING_PAYPAL_SDK,
  EVENT_TICKET_CHECKOUT_SET_USER_TOKEN,
} from "../actions/eventTicketCheckout.actions";

export interface IState {
  userToken: string | null;
  eventTicketCouponCode: string | null;
  isLoadingPayPalSDK: boolean;
  requests: {
    eventTicketRequest: {
      isLoading: boolean;
      isError: boolean;
      eventTicket: IEventTicket | null;
    };
  };
}

export const initialState: IState = {
  userToken: null,
  eventTicketCouponCode: null,
  isLoadingPayPalSDK: false,
  requests: {
    eventTicketRequest: {
      isLoading: false,
      isError: false,
      eventTicket: null,
    },
  },
};

export const reducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case EVENT_TICKET_CHECKOUT_SET_USER_TOKEN:
      return {
        ...state,
        userToken: action.payload.userToken,
      };

    case EVENT_TICKET_CHECKOUT_EVENT_TICKET_FETCH_REQUEST:
      return {
        ...state,
        requests: {
          ...state.requests,
          eventTicketRequest: {
            ...state.requests.eventTicketRequest,
            isLoading: true,
            isError: false,
          },
        },
      };

    case EVENT_TICKET_CHECKOUT_EVENT_TICKET_FETCH_SUCCESS:
      return {
        ...state,
        requests: {
          ...state.requests,
          eventTicketRequest: {
            ...state.requests.eventTicketRequest,
            isLoading: false,
            eventTicket: action.payload.eventTicket,
          },
        },
      };

    case EVENT_TICKET_CHECKOUT_EVENT_TICKET_FETCH_FAIL:
      return {
        ...state,
        requests: {
          ...state.requests,
          eventTicketRequest: {
            ...state.requests.eventTicketRequest,
            isLoading: false,
            isError: true,
          },
        },
      };

    case EVENT_TICKET_CHECKOUT_SET_EVENT_TICKET_COUPON_CODE:
      return {
        ...state,
        eventTicketCouponCode: action.payload.eventTicketCouponCode,
      };

    case EVENT_TICKET_CHECKOUT_SET_IS_LOADING_PAYPAL_SDK:
      return {
        ...state,
        isLoadingPayPalSDK: action.payload.isLoadingPayPalSDK,
      };

    default:
      return state;
  }
};
