import { IEvent } from "../../types/types";

export const NAV_SET_SEARCH_INPUT = "NAV_SET_SEARCH_INPUT";
export const NAV_SEARCH_FETCH_REQUEST = "NAV_SEARCH_FETCH_REQUEST";
export const NAV_SEARCH_FETCH_SUCCESS = "NAV_SEARCH_FETCH_SUCCESS";
export const NAV_SEARCH_FETCH_FAIL = "NAV_SEARCH_FETCH_FAIL";
export const NAV_SET_SHOW_BACKGROUND = "NAV_SET_SHOW_BACKGROUND";

export const navSetSearchInput = (input: string) => {
  return {
    type: NAV_SET_SEARCH_INPUT,
    payload: input,
  };
};

export const navSearchFetchRequest = () => {
  return {
    type: NAV_SEARCH_FETCH_REQUEST,
  };
};

export const navSearchFetchSuccess = (events: IEvent[]) => {
  return {
    type: NAV_SEARCH_FETCH_SUCCESS,
    payload: events,
  };
};

export const navSearchFetchFail = () => {
  return {
    type: NAV_SEARCH_FETCH_FAIL,
  };
};

export const navSetShowBackground = (show: boolean) => {
  return {
    type: NAV_SET_SHOW_BACKGROUND,
    payload: show,
  };
};
