import { IEventTicket } from "../../types/types";

export const EVENT_TICKET_CHECKOUT_SET_USER_TOKEN =
  "EVENT_TICKET_CHECKOUT_SET_USER_TOKEN";
export const EVENT_TICKET_CHECKOUT_EVENT_TICKET_FETCH_REQUEST =
  "EVENT_TICKET_CHECKOUT_EVENT_TICKET_FETCH_REQUEST";
export const EVENT_TICKET_CHECKOUT_EVENT_TICKET_FETCH_SUCCESS =
  "EVENT_TICKET_CHECKOUT_EVENT_TICKET_FETCH_SUCCESS";
export const EVENT_TICKET_CHECKOUT_EVENT_TICKET_FETCH_FAIL =
  "EVENT_TICKET_CHECKOUT_EVENT_TICKET_FETCH_FAIL";
export const EVENT_TICKET_CHECKOUT_SET_EVENT_TICKET_COUPON_CODE =
  "EVENT_TICKET_CHECKOUT_SET_EVENT_TICKET_COUPON_CODE";
export const EVENT_TICKET_CHECKOUT_SET_IS_LOADING_PAYPAL_SDK =
  "EVENT_TICKET_CHECKOUT_SET_IS_LOADING_PAYPAL_SDK";

export const eventTicketCheckoutSetUserToken = (data: {
  userToken: string;
}) => {
  return {
    type: EVENT_TICKET_CHECKOUT_SET_USER_TOKEN,
    payload: data,
  };
};

export const eventTicketCheckoutEventTicketFetchRequest = () => {
  return {
    type: EVENT_TICKET_CHECKOUT_EVENT_TICKET_FETCH_REQUEST,
  };
};

export const eventTicketCheckoutEventTicketFetchSuccess = (data: {
  eventTicket: IEventTicket;
}) => {
  return {
    type: EVENT_TICKET_CHECKOUT_EVENT_TICKET_FETCH_SUCCESS,
    payload: data,
  };
};

export const eventTicketCheckoutEventTicketFetchFail = () => {
  return {
    type: EVENT_TICKET_CHECKOUT_EVENT_TICKET_FETCH_FAIL,
  };
};

export function eventTicketCheckoutSetEventTicketCouponCode(data: {
  eventTicketCouponCode: string;
}) {
  return {
    type: EVENT_TICKET_CHECKOUT_SET_EVENT_TICKET_COUPON_CODE,
    payload: data,
  };
}

export function eventTicketCheckoutSetIsLoadingPayPalSDK(data: {
  isLoadingPayPalSDK: boolean;
}) {
  return {
    type: EVENT_TICKET_CHECKOUT_SET_IS_LOADING_PAYPAL_SDK,
    payload: data,
  };
}
