import { NODE_ENV } from "../../config/config";
import { BASE_URL } from "../../services/api/api-config";

export function onShowNav({ showNav }: { showNav: string | null }) {
  if (showNav === "false") {
    return false;
  }
  return true;
}

export function onShowFooter({ showFooter }: { showFooter: string | null }) {
  if (showFooter === "false") {
    return false;
  }
  return true;
}

export function onShowAccessibility({
  showAccessibility,
}: {
  showAccessibility: string | null;
}) {
  if (showAccessibility === "false") {
    return false;
  }
  return true;
}

export function onGetRunningEnv(): string {
  switch (NODE_ENV) {
    case "development":
      return "Running DEV environment";

    case "sandbox":
      return "Running SANDBOX environment";

    case "production":
      return "Running PROD environment";
  }
}

export function onMount() {
  console.log(onGetRunningEnv());
  console.log(`Server url: ${BASE_URL}`);
}
