export const CREATE_DJ_FETCH_REQUEST = "CREATE_DJ_FETCH_REQUEST";
export const CREATE_DJ_FETCH_SUCCESS = "CREATE_DJ_FETCH_SUCCESS";
export const CREATE_DJ_FETCH_FAIL = "CREATE_DJ_FETCH_FAIL";
export const CREATE_DJ_UPLOAD_PROFILE_IMAGE_FETCH_REQUEST =
  "CREATE_DJ_UPLOAD_PROFILE_IMAGE_FETCH_REQUEST";
export const CREATE_DJ_UPLOAD_PROFILE_IMAGE_FETCH_SUCCESS =
  "CREATE_DJ_UPLOAD_PROFILE_IMAGE_FETCH_SUCCESS";
export const CREATE_DJ_UPLOAD_PROFILE_IMAGE_FETCH_FAIL =
  "CREATE_DJ_UPLOAD_PROFILE_IMAGE_FETCH_FAIL";
export const CREATE_DJ_UPLOAD_VIDEO_FETCH_REQUEST =
  "CREATE_DJ_UPLOAD_VIDEO_FETCH_REQUEST";
export const CREATE_DJ_UPLOAD_VIDEO_FETCH_SUCCESS =
  "CREATE_DJ_UPLOAD_VIDEO_FETCH_SUCCESS";
export const CREATE_DJ_UPLOAD_VIDEO_FETCH_FAIL =
  "CREATE_DJ_UPLOAD_VIDEO_FETCH_FAIL";
export const CREATE_DJ_UPLOAD_PROFILE_IMAGE_RESET =
  "CREATE_DJ_UPLOAD_PROFILE_IMAGE_RESET";
export const CREATE_DJ_UPLOAD_VIDEO_RESET = "CREATE_DJ_UPLOAD_VIDEO_RESET";
export const CREATE_DJ_SUBMIT_FETCH_REQUEST = "CREATE_DJ_SUBMIT_FETCH_REQUEST";
export const CREATE_DJ_SUBMIT_FETCH_SUCCESS = "CREATE_DJ_SUBMIT_FETCH_SUCCESS";
export const CREATE_DJ_SUBMIT_FETCH_FAIL = "CREATE_DJ_SUBMIT_FETCH_FAIL";

export const createDjFetchRequest = () => {
  return {
    type: CREATE_DJ_FETCH_REQUEST,
  };
};

export const createDjFetchSuccess = () => {
  return {
    type: CREATE_DJ_FETCH_SUCCESS,
  };
};

export const createDjFetchFail = () => {
  return {
    type: CREATE_DJ_FETCH_FAIL,
  };
};

export const createDjUploadProfileImageFetchRequest = () => {
  return {
    type: CREATE_DJ_UPLOAD_PROFILE_IMAGE_FETCH_REQUEST,
  };
};

export const createDjUploadProfileImageFetchSuccess = ({
  profileImageUrl,
}: {
  profileImageUrl: string;
}) => {
  return {
    type: CREATE_DJ_UPLOAD_PROFILE_IMAGE_FETCH_SUCCESS,
    payload: profileImageUrl,
  };
};

export const createDjUploadProfileImageFetchFail = () => {
  return {
    type: CREATE_DJ_UPLOAD_PROFILE_IMAGE_FETCH_FAIL,
  };
};

export const createDjUploadVideoFetchRequest = () => {
  return {
    type: CREATE_DJ_UPLOAD_VIDEO_FETCH_REQUEST,
  };
};

export const createDjUploadVideoFetchSuccess = ({
  videoUrl,
}: {
  videoUrl: string;
}) => {
  return {
    type: CREATE_DJ_UPLOAD_VIDEO_FETCH_SUCCESS,
    payload: videoUrl,
  };
};

export const createDjUploadVideoFetchFail = () => {
  return {
    type: CREATE_DJ_UPLOAD_VIDEO_FETCH_FAIL,
  };
};

export const createDjUploadProfileImageReset = () => {
  return {
    type: CREATE_DJ_UPLOAD_PROFILE_IMAGE_RESET,
  };
};

export const createDjUploadVideoReset = () => {
  return {
    type: CREATE_DJ_UPLOAD_VIDEO_RESET,
  };
};

export const createDjSubmitFetchRequest = () => {
  return {
    type: CREATE_DJ_SUBMIT_FETCH_REQUEST,
  };
};

export const createDjSubmitFetchSuccess = () => {
  return {
    type: CREATE_DJ_SUBMIT_FETCH_SUCCESS,
  };
};

export const createDjSubmitFetchFail = () => {
  return {
    type: CREATE_DJ_SUBMIT_FETCH_FAIL,
  };
};
