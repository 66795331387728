import React, { Suspense } from "react";
import styles from "./App.module.scss";
import { BrowserRouter as Router } from "react-router-dom";
import { Loader } from "./components/Loader/Loader";
import { NavigationRouter } from "./navigation/NavigationRouter/NavigationRouter";

function App() {
  return (
    <div className={styles.container}>
      <Suspense fallback={<Loader />}>
        <Router>
          <NavigationRouter />
        </Router>
      </Suspense>
    </div>
  );
}

export default App;
