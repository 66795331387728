import React, { FC, lazy, Suspense, useEffect, useMemo } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import {
  ABOUT,
  ACCESSBILITY_POLICY,
  CANCEL_TERMS,
  EVENT_TICKET_CHECKOUT,
  CONTACT_US,
  CREATE_CLUB,
  CREATE_DJ,
  CREATE_EVENT_APP,
  EVENT_DETAILS,
  HOME,
  NOT_FOUND,
  PRIVACY_POLICY,
  PRODUCER,
  TERMS_CONDITIONS,
  UPDATE_CLUB,
  EVENT_SCANNER,
} from "../CONSTANS";
import {
  onMount,
  onShowAccessibility,
  onShowFooter,
  onShowNav,
} from "./Navigation.controller";

const Nav = lazy(() =>
  import("../../components/App/Nav/Nav").then((module) => ({
    default: module.Nav,
  }))
);
const Footer = lazy(() =>
  import("../../components/App/Footer/Footer").then((module) => ({
    default: module.Footer,
  }))
);
const Accessibility = lazy(() =>
  import("../../components/App/Accessibility/Accessibility").then((module) => ({
    default: module.Accessibility,
  }))
);
const CreateDj = lazy(() =>
  import("../../screens/CreateDj/CreateDj").then((module) => ({
    default: module.CreateDj,
  }))
);
const CreateClub = lazy(() =>
  import("../../screens/CreateClub/CreateClub").then((module) => ({
    default: module.CreateClub,
  }))
);
const EventTicketCheckout = lazy(() =>
  import("../../screens/EventTicketCheckout/EventTicketCheckout").then(
    (module) => ({
      default: module.EventTicketCheckout,
    })
  )
);
const Home = lazy(() =>
  import("../../screens/Home/Home").then((module) => ({ default: module.Home }))
);
const EventDetails = lazy(() =>
  import("../../screens/EventDetails/EventDetails").then((module) => ({
    default: module.EventDetails,
  }))
);
const TermsConditions = lazy(() =>
  import("../../screens/TermsConditions/TermsConditions").then((module) => ({
    default: module.TermsConditions,
  }))
);
const CancelTerms = lazy(() =>
  import("../../screens/CancelTerms/CancelTerms").then((module) => ({
    default: module.CancelTerms,
  }))
);
const AccessPolicy = lazy(() =>
  import("../../screens/AccessPolicy/AccessPolicy").then((module) => ({
    default: module.AccessPolicy,
  }))
);
const About = lazy(() =>
  import("../../screens/About/About").then((module) => ({
    default: module.About,
  }))
);
const Producer = lazy(() =>
  import("../../screens/Producer/Producer").then((module) => ({
    default: module.Producer,
  }))
);
const NotFound = lazy(() =>
  import("../../screens/NotFound/NotFound").then((module) => ({
    default: module.NotFound,
  }))
);
const ContactUs = lazy(() =>
  import("../../screens/ContactUs/ContactUs").then((module) => ({
    default: module.ContactUs,
  }))
);
const PrivacyPolicy = lazy(() =>
  import("../../screens/PrivacyPolicy/PrivacyPolicy").then((module) => ({
    default: module.PrivacyPolicy,
  }))
);
const CreateEventApp = lazy(() =>
  import("../../screens/CreateEventApp/CreateEventApp").then((module) => ({
    default: module.CreateEventApp,
  }))
);
const UpdateClub = lazy(() =>
  import("../../screens/UpdateClub/UpdateClub").then((module) => ({
    default: module.UpdateClub,
  }))
);
const EventScanner = lazy(() =>
  import("../../screens/EventScanner/EventScanner").then((module) => ({
    default: module.EventScanner,
  }))
);

interface IProps {}

export const NavigationRouter: FC<IProps> = React.memo(({}) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const showNav = useMemo(
    () => onShowNav({ showNav: queryParams.get("showNav") }),
    []
  );
  const showFooter = useMemo(
    () => onShowFooter({ showFooter: queryParams.get("showFooter") }),
    []
  );
  const showAccessibility = useMemo(
    () =>
      onShowAccessibility({
        showAccessibility: queryParams.get("showAccessibility"),
      }),
    []
  );

  useEffect(() => {
    onMount();
  }, []);

  return (
    <>
      {showNav && <Nav />}
      <Routes>
        <Route path={HOME} element={<Home />} />
        <Route path={EVENT_TICKET_CHECKOUT} element={<EventTicketCheckout />} />
        <Route path={EVENT_DETAILS + ":eventId"} element={<EventDetails />} />
        <Route path={PRIVACY_POLICY} element={<PrivacyPolicy />} />
        <Route path={TERMS_CONDITIONS} element={<TermsConditions />} />
        <Route path={CANCEL_TERMS} element={<CancelTerms />} />
        <Route path={ACCESSBILITY_POLICY} element={<AccessPolicy />} />
        <Route path={ABOUT} element={<About />} />
        <Route path={PRODUCER} element={<Producer />} />
        <Route path={CONTACT_US} element={<ContactUs />} />
        <Route path={CREATE_EVENT_APP} element={<CreateEventApp />} />
        <Route path={CREATE_CLUB} element={<CreateClub />} />
        <Route path={CREATE_DJ} element={<CreateDj />} />
        <Route path={UPDATE_CLUB} element={<UpdateClub />} />
        <Route path={NOT_FOUND} element={<NotFound />} />
        <Route path={EVENT_SCANNER} element={<EventScanner />} />
      </Routes>
      <Suspense fallback={<></>}>
        {showAccessibility && <Accessibility />}
      </Suspense>
      {showFooter && <Footer />}
    </>
  );
});
