import { IAction, IPlaceLocation } from "../../types/types";
import {
  CREATE_CLUB_FETCH_REQUEST,
  CREATE_CLUB_FETCH_SUCCESS,
  CREATE_CLUB_FETCH_FAIL,
  CREATE_CLUB_UPLOAD_IMAGE_FETCH_REQUEST,
  CREATE_CLUB_UPLOAD_IMAGE_FETCH_SUCCESS,
  CREATE_CLUB_UPLOAD_IMAGE_FETCH_FAIL,
  CREATE_CLUB_UPLOAD_VIDEO_FETCH_REQUEST,
  CREATE_CLUB_UPLOAD_VIDEO_FETCH_SUCCESS,
  CREATE_CLUB_SUBMIT_FETCH_FAIL,
  CREATE_CLUB_SUBMIT_FETCH_SUCCESS,
  CREATE_CLUB_SUBMIT_FETCH_REQUEST,
  CREATE_CLUB_UPLOAD_VIDEO_RESET,
  CREATE_CLUB_UPLOAD_IMAGE_RESET,
  CREATE_CLUB_UPLOAD_VIDEO_FETCH_FAIL,
  CREATE_CLUB_UPLOAD_LOGO_IMAGE_FETCH_REQUEST,
  CREATE_CLUB_UPLOAD_LOGO_IMAGE_FETCH_SUCCESS,
  CREATE_CLUB_UPLOAD_LOGO_IMAGE_FETCH_FAIL,
  CREATE_CLUB_UPLOAD_LOGO_IMAGE_RESET,
  CREATE_CLUB_SET_CLUB_LOCATION,
  CREATE_CLUB_SET_SHOW_LOCATION_MODAL,
  CREATE_CLUB_CLUB_LOCATION_MODAL_SET_CLUB_LOCATION,
  CREATE_CLUB_LOCATION_MODAL_RESET,
  CREATE_CLUB_SET_SHOW_ADDRESS_REQUIRED_MESSAGE,
} from "../actions/createClub.actions";

export interface IState {
  clubLocation: IPlaceLocation | null;
  form: {
    inputs: {
      address: {
        showAddressRequiredMessage: boolean;
      };
    };
  };
  requests: {
    createClub: {
      isLoading: boolean;
      isError: boolean;
      isSuccess: boolean;
    };
    uploadImage: {
      isLoading: boolean;
      isError: boolean;
      imageUrl: string | null;
    };
    uploadLogoImage: {
      isLoading: boolean;
      isError: boolean;
      imageUrl: string | null;
    };
    uploadVideo: {
      isLoading: boolean;
      isError: boolean;
      videoUrl: string | null;
    };
    submitCreateClub: {
      isLoading: boolean;
      isError: boolean;
      isSuccess: boolean;
    };
  };
  modals: {
    clubLocation: {
      showModal: boolean;
      clubLocation: IPlaceLocation | null;
    };
  };
}

const initialState: IState = {
  clubLocation: null,
  form: {
    inputs: {
      address: {
        showAddressRequiredMessage: false,
      },
    },
  },
  requests: {
    createClub: {
      isLoading: false,
      isError: false,
      isSuccess: false,
    },
    uploadImage: {
      isLoading: false,
      isError: false,
      imageUrl: null,
    },
    uploadLogoImage: {
      isLoading: false,
      isError: false,
      imageUrl: null,
    },
    uploadVideo: {
      isLoading: false,
      isError: false,
      videoUrl: null,
    },
    submitCreateClub: {
      isLoading: false,
      isError: false,
      isSuccess: false,
    },
  },
  modals: {
    clubLocation: {
      showModal: false,
      clubLocation: null,
    },
  },
};

export const reducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case CREATE_CLUB_FETCH_REQUEST:
      return {
        ...state,
        requests: {
          ...state.requests,
          createClub: {
            ...state.requests.createClub,
            isLoading: true,
            isError: false,
          },
        },
      };

    case CREATE_CLUB_FETCH_SUCCESS:
      return {
        ...state,
        requests: {
          ...state.requests,
          createClub: {
            ...state.requests.createClub,
            isLoading: false,
            isSuccess: true,
          },
        },
      };

    case CREATE_CLUB_FETCH_FAIL:
      return {
        ...state,
        requests: {
          ...state.requests,
          createClub: {
            ...state.requests.createClub,
            isLoading: false,
            isError: true,
          },
        },
      };

    case CREATE_CLUB_UPLOAD_IMAGE_FETCH_REQUEST:
      return {
        ...state,
        requests: {
          ...state.requests,
          uploadImage: {
            ...state.requests.uploadImage,
            isLoading: true,
            isError: false,
          },
        },
      };

    case CREATE_CLUB_UPLOAD_IMAGE_FETCH_SUCCESS:
      return {
        ...state,
        requests: {
          ...state.requests,
          uploadImage: {
            ...state.requests.uploadImage,
            isLoading: false,
            imageUrl: action.payload,
          },
        },
      };

    case CREATE_CLUB_UPLOAD_IMAGE_FETCH_FAIL:
      return {
        ...state,
        requests: {
          ...state.requests,
          uploadImage: {
            ...state.requests.uploadImage,
            isLoading: false,
            isError: true,
          },
        },
      };

    case CREATE_CLUB_UPLOAD_VIDEO_FETCH_REQUEST:
      return {
        ...state,
        requests: {
          ...state.requests,
          uploadVideo: {
            ...state.requests.uploadVideo,
            isLoading: true,
            isError: false,
          },
        },
      };

    case CREATE_CLUB_UPLOAD_VIDEO_FETCH_SUCCESS:
      return {
        ...state,
        requests: {
          ...state.requests,
          uploadVideo: {
            ...state.requests.uploadVideo,
            isLoading: false,
            videoUrl: action.payload,
          },
        },
      };

    case CREATE_CLUB_UPLOAD_VIDEO_FETCH_FAIL:
      return {
        ...state,
        requests: {
          ...state.requests,
          uploadVideo: {
            ...state.requests.uploadVideo,
            isLoading: false,
            isError: true,
          },
        },
      };

    case CREATE_CLUB_UPLOAD_IMAGE_RESET:
      return {
        ...state,
        requests: {
          ...state.requests,
          uploadImage: {
            ...initialState.requests.uploadImage,
          },
        },
      };

    case CREATE_CLUB_UPLOAD_VIDEO_RESET:
      return {
        ...state,
        requests: {
          ...state.requests,
          uploadVideo: {
            ...initialState.requests.uploadVideo,
          },
        },
      };

    case CREATE_CLUB_SUBMIT_FETCH_REQUEST:
      return {
        ...state,
        requests: {
          ...state.requests,
          submitCreateClub: {
            ...state.requests.submitCreateClub,
            isLoading: true,
            isError: false,
          },
        },
      };

    case CREATE_CLUB_SUBMIT_FETCH_SUCCESS:
      return {
        ...state,
        requests: {
          ...state.requests,
          submitCreateClub: {
            ...state.requests.submitCreateClub,
            isLoading: false,
            isSuccess: true,
          },
        },
      };

    case CREATE_CLUB_SUBMIT_FETCH_FAIL:
      return {
        ...state,
        requests: {
          ...state.requests,
          submitCreateClub: {
            ...state.requests.submitCreateClub,
            isLoading: false,
            isError: true,
          },
        },
      };

    case CREATE_CLUB_UPLOAD_LOGO_IMAGE_FETCH_REQUEST:
      return {
        ...state,
        requests: {
          ...state.requests,
          uploadLogoImage: {
            ...state.requests.uploadLogoImage,
            isLoading: true,
            isError: false,
          },
        },
      };

    case CREATE_CLUB_UPLOAD_LOGO_IMAGE_FETCH_SUCCESS:
      return {
        ...state,
        requests: {
          ...state.requests,
          uploadLogoImage: {
            ...state.requests.uploadLogoImage,
            isLoading: false,
            imageUrl: action.payload,
          },
        },
      };

    case CREATE_CLUB_UPLOAD_LOGO_IMAGE_FETCH_FAIL:
      return {
        ...state,
        requests: {
          ...state.requests,
          uploadLogoImage: {
            ...state.requests.uploadLogoImage,
            isLoading: false,
            isError: true,
          },
        },
      };

    case CREATE_CLUB_UPLOAD_LOGO_IMAGE_RESET:
      return {
        ...state,
        requests: {
          ...state.requests,
          uploadLogoImage: {
            ...initialState.requests.uploadLogoImage,
          },
        },
      };

    case CREATE_CLUB_SET_CLUB_LOCATION:
      return {
        ...state,
        clubLocation: action.payload,
      };

    case CREATE_CLUB_SET_SHOW_LOCATION_MODAL:
      return {
        ...state,
        modals: {
          ...state.modals,
          clubLocation: {
            ...state.modals.clubLocation,
            showModal: action.payload,
          },
        },
      };

    case CREATE_CLUB_CLUB_LOCATION_MODAL_SET_CLUB_LOCATION:
      return {
        ...state,
        modals: {
          ...state.modals,
          clubLocation: {
            ...state.modals.clubLocation,
            clubLocation: action.payload,
          },
        },
      };

    case CREATE_CLUB_LOCATION_MODAL_RESET:
      return {
        ...state,
        modals: {
          ...state.modals,
          clubLocation: {
            ...initialState.modals.clubLocation,
          },
        },
      };

    case CREATE_CLUB_SET_SHOW_ADDRESS_REQUIRED_MESSAGE:
      return {
        ...state,
        form: {
          ...state.form,
          inputs: {
            address: {
              ...state.form.inputs.address,
              showAddressRequiredMessage: action.payload,
            },
          },
        },
      };

    default:
      return state;
  }
};
